var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'slide-close': !_vm.propertyDetailPanel,
      'slide-open': _vm.propertyDetailPanel
    },
    attrs: {
      "id": "property-detail-panel"
    }
  }, [_c('div', {
    class: {
      'black-preview-panel black-panel-open': !_vm.propertyDetailPanel,
      'black-preview-panel black-panel-closed': _vm.propertyDetailPanel
    },
    on: {
      "click": function ($event) {
        return _vm.togglePropertyDetailPanel();
      }
    }
  }, [_c('div', {
    staticClass: "name-wrapper"
  }, [_c('h4', [_vm._v(_vm._s(_vm.development.projectName))])])]), _c('div', {
    class: {
      'content-wrapper content-close': !_vm.propertyDetailPanel,
      'content-wrapper content-open': _vm.propertyDetailPanel
    }
  }, [_c('div', {
    staticClass: "panel-header"
  }, [_c('div', {
    staticClass: "top-buttons-row"
  }, [_c('BaseButton', {
    attrs: {
      "size": 'small',
      "label": _vm.$t('Hide'),
      "icon-left": 'action-add-row.svg'
    },
    on: {
      "click": function ($event) {
        return _vm.togglePropertyDetailPanel();
      }
    }
  }), _c('EditButton', {
    on: {
      "clicked": function ($event) {
        return _vm.openSidepanelAdd(_vm.development);
      }
    }
  })], 1), _c('div', {
    key: _vm.refresh,
    staticClass: "panel-main-info"
  }, [_c('h4', [_vm._v(_vm._s(_vm.development.projectName))]), _vm.development.contactPerson && _vm.development.contactPerson.contactName != null ? _c('v-row', {
    staticClass: "customer-field-row",
    staticStyle: {
      "flex-wrap": "nowrap",
      "margin-top": "24px"
    }
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Contact")) + " ")]), _c('p', {
    staticClass: "deal-item-name link",
    staticStyle: {
      "width": "50%"
    },
    on: {
      "click": function ($event) {
        return _vm.openContactPanel(_vm.development.contactPerson);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.development.contactPerson.contactName) + " ")])]) : _vm._e(), _vm.development.client ? _c('v-row', {
    staticStyle: {
      "flex-wrap": "nowrap",
      "gap": "24px"
    }
  }, [_c('p', {
    staticClass: "deal-item",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Client")) + " ")]), _vm.development.client.company_name ? _c('p', {
    staticClass: "deal-item-name link",
    staticStyle: {
      "width": "50%",
      "text-align": "initial",
      "word-break": "break-word"
    },
    on: {
      "click": function ($event) {
        return _vm.openOrganization(_vm.development.contactPerson);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.development.client.company_name) + " ")]) : _vm._e(), !_vm.development.client.company_name ? _c('p', {
    staticClass: "deal-item-name link",
    staticStyle: {
      "width": "50%",
      "text-align": "initial",
      "word-break": "break-word"
    },
    on: {
      "click": function ($event) {
        return _vm.openContactPanel(_vm.development.client);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.development.client.contactName) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "separator",
    staticStyle: {
      "margin-top": "20px"
    }
  }), _c('div', {
    staticClass: "info-section"
  }, [_vm.development.address ? _c('v-row', {
    staticClass: "development-info-row top24"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/map_marker.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "deal-item-name"
  }, [_vm._v(_vm._s(_vm.development.address.address))])]) : _vm._e(), _vm.development.price ? _c('v-row', {
    staticClass: "development-info-row"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/euroSign.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "deal-item-name"
  }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.development.price, "€")) + " ")])]) : _vm._e(), _vm.development.propertySize ? _c('v-row', {
    staticClass: "development-info-row"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/area.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "deal-item-name"
  }, [_vm._v(" " + _vm._s(_vm.development.propertySize) + " m² ")])]) : _vm._e(), _vm.development.projectWebsite ? _c('v-row', {
    staticClass: "development-info-row",
    staticStyle: {
      "flex-wrap": "nowrap"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/add-link.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "deal-item-name link",
    staticStyle: {
      "text-align": "initial",
      "word-break": "break-word"
    },
    on: {
      "click": function ($event) {
        return _vm.openProjectLink(_vm.development.projectWebsite);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.development.projectWebsite) + " ")])]) : _vm._e(), _c('v-row', {
    staticClass: "full-info-row",
    on: {
      "click": function ($event) {
        return _vm.openDetailPanel();
      }
    }
  }, [_c('p', {
    staticClass: "link-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("ViewFullInfo")) + " ")])])], 1)], 1)]), _c('div', {
    staticClass: "separator",
    staticStyle: {
      "margin-top": "20px"
    }
  }), _c('div', {
    staticClass: "broker-info"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/users.svg"),
      "alt": ""
    }
  }), _vm._l(_vm.development.access.users, function (creator, index) {
    return _c('span', {
      key: index,
      staticClass: "button-small hoverable-info",
      staticStyle: {
        "margin-left": "-2px"
      },
      on: {
        "click": function (event) {
          return _vm.openBrokerDetailPanel(event, creator);
        }
      }
    }, [_vm._v(" " + _vm._s(creator.name) + " " + _vm._s(creator.ownershipType === "owner" ? "(" + _vm.$t("Owner").toLowerCase() + ")" : "") + " ")]);
  }), _vm.user.isAdmin || _vm.user._id === _vm.development.creator.userId ? _c('button', {
    staticClass: "animated-button",
    on: {
      "click": function ($event) {
        return _vm.openAddUsers();
      }
    }
  }, [_c('p', {
    staticStyle: {
      "font-size": "16px",
      "margin-top": "-1px"
    }
  }, [_vm._v("+")])]) : _vm._e()], 2), _c('div', {
    staticClass: "separator"
  }), _vm.development.assetClass !== 'land' ? _c('BaseAccordion', {
    attrs: {
      "heading-small": true,
      "title": _vm.$t('Immovables'),
      "button-name": _vm.$t('Add'),
      "show-bot-divider": true,
      "parent-name": 'developer',
      "identifier": 'immovables',
      "button-icon": 'plus.svg'
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.openBuildingPanel();
      }
    }
  }, [_vm._l(_vm.development.buildings, function (building) {
    return _c('div', {
      key: building._id,
      staticClass: "building-info"
    }, [building.buildingName ? _c('v-row', {
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.openBuildingDetailPanel(building);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/building20x20.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticStyle: {
        "margin-left": "8px",
        "text-decoration": "underline"
      }
    }, [_vm._v(" " + _vm._s(building.buildingName) + " ")])]) : _vm._e(), !building.buildingName ? _c('v-row', {
      staticStyle: {
        "cursor": "pointer",
        "flex-wrap": "nowrap"
      },
      on: {
        "click": function ($event) {
          return _vm.openBuildingDetailPanel(building);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/building20x20.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticStyle: {
        "margin-left": "8px",
        "text-decoration": "underline"
      }
    }, [_vm._v(" " + _vm._s(building.addressObjects[0].address) + " ")])]) : _vm._e(), _c('v-row', {
      staticClass: "customer-field-row"
    }, [_c('p', {
      staticClass: "deal-item",
      staticStyle: {
        "width": "176px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Units")) + " ")]), _c('p', {
      staticClass: "deal-item-name"
    }, [_vm._v(" " + _vm._s(building.units.length) + " ")])]), _c('v-row', {
      staticClass: "customer-field-row"
    }, [_c('p', {
      staticClass: "deal-item",
      staticStyle: {
        "width": "176px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Floors")) + " ")]), _c('p', {
      staticClass: "deal-item-name"
    }, [_vm._v(" " + _vm._s(_vm.countMaxFloors(building.units)) + " ")])]), _c('v-row', {
      staticClass: "customer-field-row building-sub-info",
      staticStyle: {
        "padding-bottom": "24px"
      }
    }, [_c('p', {
      staticClass: "deal-item",
      staticStyle: {
        "width": "176px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Vacancy")) + " ")]), _c('p', {
      staticClass: "deal-item-name"
    }, [_vm._v(" " + _vm._s(_vm.countLeasedUnits(building.units)) + "% ")])])], 1);
  }), _vm.development.buildings.length === 0 ? _c('v-row', {
    staticClass: "content",
    staticStyle: {
      "margin-top": "0px",
      "background-color": "white",
      "padding-left": "0px",
      "padding-top": "0px",
      "padding-right": "0px"
    }
  }, [_c('p', {
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoBuildingsAdded")) + " ")])]) : _vm._e()], 2) : _vm._e(), _c('BaseAccordion', {
    attrs: {
      "heading-small": true,
      "button-opens-accordion": true,
      "title": _vm.$t('TasksAndEvents'),
      "identifier": 'tasks',
      "counter-bg-color-red": true,
      "parent-name": 'developer',
      "show-bot-divider": true,
      "counter": _vm.tasksCounter,
      "button-icon": 'plus.svg',
      "button-name": _vm.$t('Add')
    },
    on: {
      "buttonPressed": function ($event) {
        _vm.openTaskAddWidgetCounter++;
      }
    }
  }, [_c('BaseTasksAndEvents', {
    attrs: {
      "show-toggle-button": true,
      "open-new-task-add-widget": _vm.openTaskAddWidgetCounter,
      "labels-and-order": {
        overDueTasks: {
          label: false,
          order: 2
        },
        noDueDateTasks: {
          label: false,
          order: 1
        },
        todayTasks: {
          label: false,
          order: 3
        },
        upcomingTasks: {
          label: false,
          order: 4
        }
      },
      "view": {
        name: 'development',
        itemId: _vm.development._id,
        itemName: _vm.development.name
      }
    },
    on: {
      "tasks": _vm.tasksUpdated
    }
  })], 1), _c('BaseAccordion', {
    attrs: {
      "heading-small": true,
      "button-name": _vm.$t('Add'),
      "title": _vm.$t('Notes'),
      "parent-name": 'developer',
      "identifier": 'notes',
      "button-icon": 'plus.svg',
      "show-bot-divider": true,
      "button-opens-accordion": true,
      "counter": _vm.totalNotesCounter
    },
    on: {
      "buttonPressed": function ($event) {
        _vm.addNewNoteCounter++;
      }
    }
  }, [_c('BaseNotes', {
    attrs: {
      "add-new-note-counter": _vm.addNewNoteCounter,
      "view": {
        name: 'development',
        itemId: _vm.$route.params.id,
        itemName: _vm.development.name
      }
    },
    on: {
      "notesCounter": function (value) {
        _this.totalNotesCounter = value;
      }
    }
  })], 1), _c('BaseAccordion', {
    attrs: {
      "heading-small": true,
      "button-name": _vm.$t('Add'),
      "title": _vm.$t('Files'),
      "button-opens-accordion": true,
      "button-icon": 'plus.svg',
      "counter": _vm.development.files.length,
      "parent-name": 'developer',
      "identifier": 'files'
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.fileUpload();
      }
    }
  }, [_c('FileUpload', {
    attrs: {
      "files": _vm.development.files,
      "targetId": _vm.development._id,
      "target-object": 'development',
      "showButtons": true,
      "id": 'development-general-upload'
    },
    on: {
      "success": _vm.getDevelopment,
      "update:files": function ($event) {
        return _vm.$set(_vm.development, "files", $event);
      }
    }
  }), _vm.development.files.length === 0 ? _c('v-row', {
    staticClass: "content",
    staticStyle: {
      "margin-top": "0px",
      "background-color": "white",
      "padding-left": "0px",
      "padding-top": "0px",
      "padding-right": "0px"
    }
  }, [_c('p', {
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoFilesAdded")) + " ")])]) : _vm._e()], 1)], 1), _vm.brokerDetailOpened ? _c('BrokerInfoPanel', {
    attrs: {
      "item": _vm.clickedBroker,
      "position": _vm.brokerPanelPosition
    },
    on: {
      "closed": _vm.closeBrokerDetailPanel
    }
  }) : _vm._e(), _vm.isAddBrokerOpen ? _c('AddBrokerModal', {
    attrs: {
      "item": _vm.development,
      "development-modal": true
    },
    on: {
      "update:item": function ($event) {
        _vm.development = $event;
      },
      "close-modal": function ($event) {
        _vm.isAddBrokerOpen = false;
      }
    }
  }) : _vm._e(), _vm.contactPanelOpen ? _c('ClientSidepanel', {
    attrs: {
      "customerData": _vm.contactData,
      "from-project-general": true
    },
    on: {
      "panelClosed": function ($event) {
        return _vm.closeContactPanel();
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }