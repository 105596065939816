<template>
  <div
    id="property-detail-panel"
    v-bind:class="{
      'slide-close': !propertyDetailPanel,
      'slide-open': propertyDetailPanel,
    }"
  >
    <div
      @click="togglePropertyDetailPanel()"
      v-bind:class="{
        'black-preview-panel black-panel-open': !propertyDetailPanel,
        'black-preview-panel black-panel-closed': propertyDetailPanel,
      }"
    >
      <div class="name-wrapper">
        <h4>{{ development.projectName }}</h4>
      </div>
    </div>
    <div
      v-bind:class="{
        'content-wrapper content-close': !propertyDetailPanel,
        'content-wrapper content-open': propertyDetailPanel,
      }"
    >
      <div class="panel-header">
        <div class="top-buttons-row">
          <BaseButton
            :size="'small'"
            :label="$t('Hide')"
            :icon-left="'action-add-row.svg'"
            @click="togglePropertyDetailPanel()"
          ></BaseButton>
          <EditButton @clicked="openSidepanelAdd(development)"></EditButton>
        </div>

        <div class="panel-main-info" :key="refresh">
          <h4>{{ development.projectName }}</h4>
          <v-row
            v-if="
              development.contactPerson &&
              development.contactPerson.contactName != null
            "
            class="customer-field-row"
            style="flex-wrap: nowrap; margin-top: 24px"
            ><p class="deal-item" style="width: 50%">
              {{ $t("Contact") }}
            </p>
            <p
              class="deal-item-name link"
              style="width: 50%"
              @click="openContactPanel(development.contactPerson)"
            >
              {{ development.contactPerson.contactName }}
            </p></v-row
          >

          <v-row v-if="development.client" style="flex-wrap: nowrap; gap: 24px"
            ><p class="deal-item" style="width: 50%">
              {{ $t("Client") }}
            </p>
            <p
              v-if="development.client.company_name"
              class="deal-item-name link"
              @click="openOrganization(development.contactPerson)"
              style="width: 50%; text-align: initial; word-break: break-word"
            >
              {{ development.client.company_name }}
            </p>
            <p
              v-if="!development.client.company_name"
              class="deal-item-name link"
              style="width: 50%; text-align: initial; word-break: break-word"
              @click="openContactPanel(development.client)"
            >
              {{ development.client.contactName }}
            </p>
          </v-row>
          <div class="separator" style="margin-top: 20px"></div>
          <div class="info-section">
            <v-row
              v-if="development.address"
              class="development-info-row top24"
            >
              <img :src="require('@/assets/images/map_marker.svg')" alt="" />
              <p class="deal-item-name">{{ development.address.address }}</p>
            </v-row>
            <v-row v-if="development.price" class="development-info-row">
              <img :src="require('@/assets/images/euroSign.svg')" alt="" />

              <p class="deal-item-name">
                {{ $nMapper.price(development.price, "€") }}
              </p></v-row
            >
            <v-row v-if="development.propertySize" class="development-info-row">
              <img :src="require('@/assets/images/area.svg')" alt="" />

              <p class="deal-item-name">
                {{ development.propertySize }} m²
              </p></v-row
            >
            <v-row
              v-if="development.projectWebsite"
              class="development-info-row"
              style="flex-wrap: nowrap"
              ><img :src="require('@/assets/images/add-link.svg')" alt="" />
              <p
                class="deal-item-name link"
                style="text-align: initial; word-break: break-word"
                @click="openProjectLink(development.projectWebsite)"
              >
                {{ development.projectWebsite }}
              </p></v-row
            >
            <v-row class="full-info-row" @click="openDetailPanel()">
              <p class="link-small">
                {{ $t("ViewFullInfo") }}
              </p>
            </v-row>
          </div>
        </div>
      </div>

      <div class="separator" style="margin-top: 20px"></div>

      <div class="broker-info">
        <img src="../../../assets/images/users.svg" alt="" />

        <span
          v-for="(creator, index) in development.access.users"
          class="button-small hoverable-info"
          style="margin-left: -2px"
          :key="index"
          @click="(event) => openBrokerDetailPanel(event, creator)"
        >
          {{ creator.name }}
          {{
            creator.ownershipType === "owner"
              ? "(" + $t("Owner").toLowerCase() + ")"
              : ""
          }}
        </span>
        <button
          v-if="user.isAdmin || user._id === development.creator.userId"
          class="animated-button"
          @click="openAddUsers()"
        >
          <p style="font-size: 16px; margin-top: -1px">+</p>
          <!--          <span>{{ $t("ManageUsers") }}</span>-->
        </button>
      </div>
      <div class="separator"></div>

      <BaseAccordion
        v-if="development.assetClass !== 'land'"
        :heading-small="true"
        @buttonPressed="openBuildingPanel()"
        :title="$t('Immovables')"
        :button-name="$t('Add')"
        :show-bot-divider="true"
        :parent-name="'developer'"
        :identifier="'immovables'"
        :button-icon="'plus.svg'"
      >
        <div
          v-for="building in development.buildings"
          :key="building._id"
          class="building-info"
        >
          <v-row
            style="cursor: pointer"
            v-if="building.buildingName"
            @click="openBuildingDetailPanel(building)"
          >
            <img src="@/assets/images/building20x20.svg" alt="" />
            <p style="margin-left: 8px; text-decoration: underline">
              {{ building.buildingName }}
            </p>
          </v-row>
          <v-row
            style="cursor: pointer; flex-wrap: nowrap"
            v-if="!building.buildingName"
            @click="openBuildingDetailPanel(building)"
          >
            <img src="@/assets/images/building20x20.svg" alt="" />
            <p style="margin-left: 8px; text-decoration: underline">
              {{ building.addressObjects[0].address }}
            </p>
          </v-row>
          <v-row class="customer-field-row"
            ><p class="deal-item" style="width: 176px">
              {{ $t("Units") }}
            </p>
            <p class="deal-item-name">
              {{ building.units.length }}
            </p></v-row
          >
          <v-row class="customer-field-row"
            ><p class="deal-item" style="width: 176px">
              {{ $t("Floors") }}
            </p>
            <p class="deal-item-name">
              {{ countMaxFloors(building.units) }}
            </p></v-row
          >
          <v-row
            class="customer-field-row building-sub-info"
            style="padding-bottom: 24px"
            ><p class="deal-item" style="width: 176px">
              {{ $t("Vacancy") }}
            </p>
            <p class="deal-item-name">
              {{ countLeasedUnits(building.units) }}%
            </p></v-row
          >
        </div>
        <v-row
          class="content"
          style="
            margin-top: 0px;
            background-color: white;
            padding-left: 0px;
            padding-top: 0px;
            padding-right: 0px;
          "
          v-if="development.buildings.length === 0"
        >
          <p style="color: #75787a">
            {{ $t("NoBuildingsAdded") }}
          </p></v-row
        >
      </BaseAccordion>
      <BaseAccordion
        :heading-small="true"
        @buttonPressed="openTaskAddWidgetCounter++"
        :button-opens-accordion="true"
        :title="$t('TasksAndEvents')"
        :identifier="'tasks'"
        :counter-bg-color-red="true"
        :parent-name="'developer'"
        :show-bot-divider="true"
        :counter="tasksCounter"
        :button-icon="'plus.svg'"
        :button-name="$t('Add')"
      >
        <BaseTasksAndEvents
          @tasks="tasksUpdated"
          :show-toggle-button="true"
          :open-new-task-add-widget="openTaskAddWidgetCounter"
          :labels-and-order="{
            overDueTasks: {
              label: false,
              order: 2,
            },
            noDueDateTasks: {
              label: false,
              order: 1,
            },
            todayTasks: {
              label: false,
              order: 3,
            },
            upcomingTasks: {
              label: false,
              order: 4,
            },
          }"
          :view="{
            name: 'development',
            itemId: development._id,
            itemName: development.name,
          }"
        />
      </BaseAccordion>
      <BaseAccordion
        :heading-small="true"
        @buttonPressed="addNewNoteCounter++"
        :button-name="$t('Add')"
        :title="$t('Notes')"
        :parent-name="'developer'"
        :identifier="'notes'"
        :button-icon="'plus.svg'"
        :show-bot-divider="true"
        :button-opens-accordion="true"
        :counter="totalNotesCounter"
      >
        <BaseNotes
          @notesCounter="
            (value) => {
              this.totalNotesCounter = value;
            }
          "
          :add-new-note-counter="addNewNoteCounter"
          :view="{
            name: 'development',
            itemId: $route.params.id,
            itemName: development.name,
          }"
        />
      </BaseAccordion>
      <BaseAccordion
        :heading-small="true"
        :button-name="$t('Add')"
        :title="$t('Files')"
        :button-opens-accordion="true"
        @buttonPressed="fileUpload()"
        :button-icon="'plus.svg'"
        :counter="development.files.length"
        :parent-name="'developer'"
        :identifier="'files'"
      >
        <FileUpload
          @success="getDevelopment"
          :files.sync="development.files"
          :targetId="development._id"
          :target-object="'development'"
          :showButtons="true"
          :id="'development-general-upload'"
        />
        <v-row
          class="content"
          style="
            margin-top: 0px;
            background-color: white;
            padding-left: 0px;
            padding-top: 0px;
            padding-right: 0px;
          "
          v-if="development.files.length === 0"
        >
          <p style="color: #75787a">
            {{ $t("NoFilesAdded") }}
          </p></v-row
        >
      </BaseAccordion>
    </div>
    <BrokerInfoPanel
      v-if="brokerDetailOpened"
      :item="clickedBroker"
      @closed="closeBrokerDetailPanel"
      :position="brokerPanelPosition"
    />
    <AddBrokerModal
      v-if="isAddBrokerOpen"
      :item.sync="development"
      @close-modal="isAddBrokerOpen = false"
      :development-modal="true"
    />
    <ClientSidepanel
      v-if="contactPanelOpen"
      @panelClosed="closeContactPanel()"
      :customerData="contactData"
      :from-project-general="true"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import axios from "axios";
import BrokerInfoPanel from "@/components/common/BrokerInfoPanel.vue";
import AddBrokerModal from "@/components/common/AddBrokerModal.vue";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel.vue";
import BaseAccordion from "@/components/common/BaseAccordion";
import BaseTasksAndEvents from "@/components/common/BaseTasksAndEvents";
import BaseNotes from "@/components/common/BaseNotes";
import FileUpload from "@/components/common/FileUpload";
import EditButton from "@/components/common/EditButton";
import BaseButton from "@/components/common/BaseButton";

export default {
  name: "PropertyDetailPanel",
  props: ["development", "refresh"],
  components: {
    BrokerInfoPanel,
    AddBrokerModal,
    ClientSidepanel,
    BaseTasksAndEvents,
    BaseNotes,
    BaseAccordion,
    FileUpload,
    EditButton,
    BaseButton,
  },
  data: () => ({
    contactPanelOpen: false,
    contactData: null,
    clickedOrg: null,
    orgDetailOpen: false,
    openTaskAddWidgetCounter: 0,
    addNewNoteCounter: 0,
    newNote: {
      id: null,
      content: "",
      linkedTo: null,
      parentName: null,
      createdAt: null,
      isDisabled: true,
    },
    detailPanelOpened: false,
    clickedDevelopment: false,
    editDevelopmentPanelOpen: false,
    interiorPackageOpened: false,
    developmentUpdated: 0,
    totalNotesCounter: 0,
    leadsCounter: 0,
    refresher: 0,
    tasksCounter: 0,
    buildingDetailPanelOpen: false,
    clickedBuilding: null,
    editPanelNested: false,
    buildingPanelOpened: false,
    tasks: [],
    updaterKey: 0,
    form: {
      name: null,
      dealType: null,
      description: null,
      amount: null,
      currency: "EUR",
      service: null,
      probability: null,
      doneDate: null,
      estimatedCloseDate: null,
      pipelineStep: null,
      contactPerson: null,
      organization: null,
      objects: [],
      objectName: null,
    },
    arrow: null,
    addMarketingExpense: null,
    chosenObject: null,
    openHouse: null,
    brokerPanelPosition: null,
    brokerDetailOpened: null,
    clickedBroker: null,
    isAddBrokerOpen: false,
  }),
  computed: {
    ...mapGetters([
      "user",
      "clientSidepanelStatus",
      "developmentAccordionsStates",
      "showCompletedProjectTasks",
      "propertyDetailPanel",
    ]),
  },
  methods: {
    ...mapActions(["togglePropertyDetailPanel"]),
    openOrganization(orgRegCode) {
      if (/^\d+$/.test(orgRegCode.reg_code)) {
        this.clickedOrg = orgRegCode.reg_code;
        this.orgDetailOpen = true;
        document.documentElement.style.overflow = "hidden";
      } else {
        this.clickedOrg = orgRegCode;
        this.orgDetailOpen = true;
        document.documentElement.style.overflow = "hidden";
      }
    },
    openContactPanel(client) {
      this.contactData = client;
      this.contactPanelOpen = true;
    },
    async closeContactPanel() {
      this.contactPanelOpen = false;
    },
    openBrokerDetailPanel(event, person) {
      this.brokerPanelPosition = {
        left: event.clientX + "px",
        top: event.clientY - 60 + "px",
      };
      this.brokerDetailOpened = true;
      this.clickedBroker = person;
    },
    openAddUsers() {
      this.isAddBrokerOpen = true;
    },
    closeBrokerDetailPanel() {
      this.brokerDetailOpened = false;
    },
    countTasks() {
      let taskCounter = 0;
      let currentDate = new Date(Date.now());
      const currentTime =
        currentDate.getHours() + ":" + currentDate.getMinutes();
      currentDate = currentDate.toISOString().replace("Z", "+03:00");
      this.tasks.forEach((task) => {
        if (task.category === "open_house") {
          if (!task.numberOfParticipants) {
            taskCounter++;
          }
        }
        if (task.category === "task") {
          if (!task.isCompleted) {
            taskCounter++;
          }
        }
        if (task.category === "event") {
          if (
            (task.dates.endDate >= currentDate &&
              task.dates.endTime > currentTime) ||
            (task.dates.endDate >= currentDate && !task.dates.endTime) ||
            (task.dates.endDate > currentDate && task.dates.endTime)
          ) {
            taskCounter++;
          }
        }
      });
      this.tasksCounter = taskCounter;
    },
    countBuildingLeads() {
      this.development.buildings.forEach((building) => {
        building.leadsCounter = 0;
        if (building.units.length) {
          building.units.forEach((unit) => {
            if (unit.leads && unit.leads.length) {
              unit.leads.forEach((lead) => {
                if (lead.length !== 0 /* && lead.statusCode !== 0  */) {
                  building.leadsCounter++;
                }
              });
            }
          });
        }
      });
    },
    async getDevelopment() {
      this.$emit("getDevelopment");
    },
    fileUpload() {
      const fileContentLoaded = setInterval(() => {
        if (document.getElementById("development-general-upload")) {
          document.getElementById("development-general-upload").click();
          clearInterval(fileContentLoaded);
        }
      }, 100);
    },
    tasksUpdated(emittedTasks) {
      this.tasks = emittedTasks;
      this.countTasks();
    },
    showAllText(interior) {
      interior.showAllDescription = true;
      this.updaterKey++;
    },
    showLessText(interior) {
      interior.showAllDescription = false;
      this.updaterKey++;
    },
    openInteriorPackagesPanel() {
      this.$emit("openInteriorPackagesPanel");
      this.interiorPackageOpened = true;
    },
    closeInteriorPanel() {
      this.interiorPackageOpened = false;
    },
    countNotes() {
      let notesCounter = 0;
      this.development.notes.forEach((note) => {
        if (note.statusCode !== 0) {
          notesCounter++;
        }
      });
      this.totalNotesCounter = notesCounter;
      return this.totalNotesCounter;
    },
    countMaxFloors(units) {
      let max = Math.max.apply(
        Math,
        units.map(function (o) {
          return o.floor;
        })
      );
      if (max === Infinity || max === -Infinity) {
        return 0;
      } else {
        return max;
      }
    },
    countLeasedUnits(units) {
      let totalUnits = 0;
      let leasableUnits = 0;
      let result = 0;
      units.forEach((unit) => {
        totalUnits++;
        if (unit.leasable) {
          leasableUnits++;
        }
      });
      result = 100 - (leasableUnits / totalUnits) * 100;
      if (result.toFixed(2) === "NaN") {
        return 0;
      } else {
        return parseFloat(result.toFixed(2));
      }
    },
    openBuildingDetailPanel(building) {
      this.$emit("openBuildingDetailPanel", building);
    },

    openBuildingPanel() {
      this.$emit("openBuildingPanel");
    },
    openSidepanelAdd() {
      this.$emit("openSidepanelAdd");
    },
    closeSidepanelAdd() {
      this.editDevelopmentPanelOpen = false;
      document.getElementById("panel-wrapper-development").style.display =
        "initial";
      //this.developmentUpdated++;
      this.getDevelopment(this.$route.params.id);
    },
    openProjectLink(e) {
      if (!e.match(/^https?:\/\//i)) {
        e = "http://" + e;
      }
      window.open(e, "_blank");
    },
    openDetailPanel() {
      this.$emit("openDetailPanel");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";
.broker-info {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 0px;
  padding: 12px 0px 12px 0px;
}
.hoverable-info {
  cursor: pointer;
  background-color: #f4f5f7;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #ff5c01;
    color: #ffffff;
    font-weight: bold;
  }
}
.animated-button {
  border: 1px solid $grey500;
  border-radius: 6px;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  font-size: 12px;
  &:hover {
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #000000;
  }
}

.animated-button span {
  max-width: 0;
  -webkit-transition: max-width 0.1s;
  transition: max-width 0.1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
}

.animated-button:hover span {
  padding: 0px 2px;
  max-width: 7rem;
}

.animated-button:hover p {
  color: #ffffff;
}
#property-detail-panel {
  display: flex;
  z-index: 5;
  position: relative !important;
  flex-direction: column;
  width: 336px;
  min-width: 80px;
  //min-width: 336px;
  max-width: 336px;
  margin-left: 64px;
  margin-top: 1px;
  //padding: 20px;
  background: white;
  overflow-y: scroll;
  height: 100%;
  transition: all 600ms ease-in-out;
}

.development-info-row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: nowrap;
  img {
    width: 20px;
    height: 20px;
  }
}
.full-info-row {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
}

.black-preview-panel {
  position: fixed;
  cursor: pointer;
  height: 100vh;
  background: #16afb9;
  width: 80px;
  max-width: 80px;
  min-width: 80px;
  transition: all 600ms ease-in-out;
  opacity: 0;

  .name-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    flex-direction: column;
    justify-content: flex-start;
    padding: 48px 24px 24px;
    border-right: 1px solid #e6e8ec;
  }

  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    color: white !important;
    margin: 0 !important;
    font-size: 32px;
    line-height: 32px;
  }
}

.black-panel-open {
  display: initial;
  transition: all 600ms ease-in-out;
  animation: bar-open 1.5s forwards;
}

.black-panel-closed {
  display: none;
  transition: all 600ms ease-in-out;
}

.slide-open {
  animation: slide-open 0.6s forwards;
}

.slide-close {
  animation: slide-close 0.6s forwards;
}

.content-open {
  display: flex;
  flex-direction: column;
  //animation: content-open 0.3s forwards;
}

.content-close {
  display: none;
  //animation: content-close 0.3s forwards;
}

@keyframes bar-open {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes bar-open {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-open {
  0% {
    width: 80px;
  }
  100% {
    display: flex;
    z-index: 5;
    position: relative;
    flex-direction: column;
    min-width: 336px;
    width: 336px;
    max-width: 336px;
    margin-left: 66px;
    //padding: 20px;
    background: white;
    overflow-y: scroll;
    height: 100%;
    transition: all 600ms ease-in-out;
  }
}

@keyframes slide-open {
  0% {
    width: 80px;
  }
  100% {
    display: flex;
    z-index: 5;
    position: relative;
    flex-direction: column;
    min-width: 336px;
    width: 336px;

    max-width: 336px;
    margin-left: 64px;
    margin-top: 1px;
    //padding: 20px;
    background: white;
    overflow-y: scroll;
    height: 100%;
    transition: all 600ms ease-in-out;
  }
}

@-webkit-keyframes slide-close {
  0% {
    min-width: 80px !important;
    width: 336px;
  }
  100% {
    min-width: 80px !important;
    width: 80px;
    padding: 0;
  }
}

@keyframes slide-close {
  0% {
    width: 336px;
    min-width: 80px !important;
  }
  100% {
    min-width: 80px !important;
    width: 80px;
    padding: 0;
  }
}

@-webkit-keyframes content-open {
  0% {
    display: none;
  }
  100% {
    display: flex;
  }
}

@keyframes content-open {
  0% {
    display: none;
  }
  100% {
    display: flex;
  }
}

@-webkit-keyframes content-close {
  0% {
    display: flex;
  }
  100% {
    display: none;
  }
}

@keyframes content-close {
  0% {
    display: flex;
  }
  100% {
    display: none;
  }
}

.top-buttons-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
}

.panel-main-info {
  margin-top: 40px;
}

.info-section {
  margin-top: 24px;
}

.slide-animation-open {
  width: 336px !important;
}

.slide-animation-close {
  width: 0 !important;
}
.content-wrapper {
  background: white;
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
  min-width: 336px !important;
  overflow-x: hidden;
}

.project-second-header {
  height: 64px;
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
}

.related-person-row {
  margin-top: 12px;
  gap: 12px !important;
  padding: 0 24px 0 0;
}

.relationIcon {
  border-radius: 50%;
  padding: 0 4px;
  background: $blue;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.related-person-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.related-person-customer-name {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

#arrow-destination {
  width: 144px;
  height: 27px;
  position: absolute;
  right: 0;
  top: 0;
}

.dropdown-content {
  display: none;
  margin-top: 10px;
  margin-left: 22px;
  position: absolute;
  background-color: white;
  width: 160px;
  max-width: 160px;
  overflow: auto;
  z-index: 3;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);

  p {
    padding: 12px 12px 12px 24px;
    font-size: 14px;
    line-height: 16px;

    &:hover {
      background-color: #f4f5f7;
      cursor: pointer;
    }
  }
}

.show {
  display: block;
}

.building-sub-info {
  padding-bottom: 24px;
}

.content {
  display: block;
  padding-left: 24px;
  padding-right: 50px;
  padding-top: 24px;
  width: 100%;
  background-color: #f4f5f7;
}

/* .interior-modern-package {
  border-radius: 8px;
  border: 1px solid #e4e5e7;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
} */
.interior-package {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.interior-package:not(:last-child) {
  border-bottom: 1px solid #e4e5e7;
}

.interior-package:last-child {
  display: flex;
  border-top: unset;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  justify-content: space-between;
  padding: 16px;
}

.interior-nordic-package {
  border: 1px solid #e4e5e7;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-top: 0px !important;
  border-top: unset;
}

.interior-trend-package {
  border: 1px solid #e4e5e7;
  border-top: unset;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-top: 0px !important;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

#autoresizing {
  display: block;
  overflow: hidden;
  resize: none;
}

.content-gray {
  color: $grey800;

  img {
    filter: invert(90%) sepia(5%) saturate(21%) hue-rotate(190deg)
      brightness(87%) contrast(93%);
  }
}

.content-orange {
  color: $orange;

  img {
    filter: invert(40%) sepia(92%) saturate(1877%) hue-rotate(357deg)
      brightness(98%) contrast(110%);
  }
}

.note-row {
  flex-flow: row;
  margin-top: 16px !important;
  cursor: pointer;
  max-width: 100%;
}

.note-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box;
}

.note-text:hover {
  cursor: pointer;
  /*
  box-shadow: inset 0px 0px 0px 1px rgb(230, 232, 236);
*/
  border-radius: 8px;
}

.createdAt {
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  margin-bottom: 0 !important;

  /* grey/grey900 */

  color: #939597;
}

.add-field {
  margin-bottom: 0 !important;
  width: 100% !important;
}

/* .notes {
  padding-left: 12px;
  padding-right: 28px;
  padding-bottom: 12px;
} */
.related-content {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
}

.add-notes {
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  border-radius: 8px;
  height: 40px;
  padding: 0;
  justify-content: space-between;
}

.add-note-inside {
  width: 73px;
  height: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.text-area {
  height: fit-content !important;
}

.delete {
  background-color: #ff1e24;
  color: white;
}

.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}

.v-input__slot {
}
.notes-info-row {
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.files-info-row {
  padding: 0;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
}

.notes-col {
  padding: 0;
  padding-top: 12px;
}

.top-row {
  width: 100%;
  justify-content: flex-end;
  padding: 12px;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 100%;

  border: 1px solid #e6e8ec;
  margin-bottom: 24px;
  margin-top: 16px;
  left: 0;
  right: 0;
}

.description {
  /* padding-left: 24px;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-right: 56px; */
  border: none !important;
  outline: none !important;
  width: 100%;
  resize: none;
}

.main-wrapper {
  margin: 12px;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
}

.sub-block {
  padding: 0px;
  border-bottom: 1px solid #e6e8ec;
}

.sub-block-wrapper {
  padding: 12px;
}

.no-active {
  height: 40px;
  max-width: 65%;
}

.inputFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
  padding-top: 16px;
  padding-bottom: 24px;
}

.no-active.v-input.v-input__slot {
  border-radius: 20px;
  border: 1px solid #e6e8ec !important;
}

.related-persons {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 0 !important;
}

.contact-type {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.related-person-info {
  text-align: right;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #000000;
}

.stageSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
}

.stageSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.pipeline-name {
  display: none;
  white-space: nowrap;
  margin-left: 5px;
}

.pipeline-name-white {
  display: none;
  color: white !important;
  white-space: nowrap;
  margin-left: 5px;
}

.pipeline-middle {
  display: flex;
  flex-direction: row;
  flex: 0;
  align-items: center;
  width: 52.48px;
  height: 40px;
  padding-right: 0px;
  background-color: #e6e8ec;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.deal-item-name {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: left;

  /* black */

  color: #000000;
}

.client-name {
  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
}

.pipeline-image-front {
  width: 12px;

  path {
    fill: #e6e8ec !important;
  }
}

/* ::v-deep .sub-heading {
  padding: 0;
  padding-bottom: 12px;
} */
/* ::v-deep .add-person {
  padding: 0;
  padding-top: 12px;
} */
.stageSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

.stageSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.pipeline-left {
  position: absolute;
  left: 0px;
}

.pipeline-left-black {
  background-color: #000000 !important;
}

.pipeline-right-black {
  background-color: #000000 !important;
}

.pipeline-right {
  position: absolute;
  right: -1.2px;
}

.chosen-step-nr {
  color: white !important;
  width: 8.4px;
}

.step-nr-white {
  color: white !important;
}

.chosen-step-name {
  display: initial;
  color: white !important;
}

.chosen-step-container {
  background-color: black !important;
  width: fit-content;
  padding-right: 20px;
}

.hover-step-container {
  background-color: #fff0d4;
  width: fit-content;
  padding-right: 20px;
}

.probability {
  padding-right: 20%;
}

.customer-row {
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
}

.main-info-row {
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

/* .add-person {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
  padding-top: 12px;
} */
.files {
  padding: 0 !important;
}

.add-persons {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  gap: 8px;
  padding: 0;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.sub-heading {
  padding: 24px;
  padding-right: 56px;
}

.deal-item {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.register-code {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.vertical-line {
  background-color: #e6e8ec;
  width: 1px;
  height: 16px;
}

.description-item {
  text-overflow: ellipsis;
}

.description-item1 {
  border: none !important;
  outline: none !important;
  width: 376px;
  height: 52px;
  font-size: 14px;
  resize: none;
  padding-right: 16px;
  padding-bottom: 58px;
  padding-top: 0px;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.interior-description {
  max-height: 80px;
  overflow: hidden;
}

.interior-show-all-text {
  max-height: initial;
  overflow: visible;
}

::v-deep .v-text-field__slot textarea {
  padding-right: 40px !important;
}

.autoscale-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.interior-description-text {
  text-overflow: ellipsis;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.description-item2 {
  border: none !important;
  outline: none !important;
  width: 592px;
  height: 300px;
  font-size: 14px;
  resize: none;
  padding: 12px;
  overflow: hidden;
}

::v-deep .description-textarea > .v-text-field--outlined fieldset {
  border: 0px solid red !important;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  width: 0%;
}

.activities {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
}

.modal-open {
  overflow-x: hidden;
  overflow-y: hidden;
}

.modal-close {
  overflow-x: auto;
  overflow-y: auto;
}

.object-link:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

::v-deep
  .ui-popup-container
  ::v-deep.ui-popup-hidden
  ::v-deep.ui-popup-truncate {
  display: none !important;
}

.amount {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  height: 24px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* black */

  color: #000000;
}

.hover-open-delete {
  background-image: url("../../../assets/images/trash-red.svg");
}

.save {
  background-color: #0aaf60;
  color: white;
  border-color: #e6e8ec;
}

.contactperson {
  font-size: 14px !important;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }
}

.separator {
  border-top: 1px solid #e6e8ec;
  width: 100%;
  height: 1px;
}

.initials-text {
  display: initial;
}

.content-small-green {
  color: #0aaf60;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.self-service-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  //padding: 12px 12px;
  font-size: 14px;
  width: 100px;
  height: 32px;
  font-family: Inter;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.content-small-orange {
  color: #ff5c01;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-yellow {
  color: #a89963;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-blue {
  color: #4c8dd9;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-small-pink {
  color: #f81ca0;
  max-width: 90% !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.green-font {
  background: #f2ffe0;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.blue-font {
  background: #dbe8f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pink-font {
  background: #fff2f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.orange-font {
  background: #fff0d4;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.yellow-font {
  background: #fff0d4;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gray-font {
  background: #f4f5f7;
  border-radius: 4px;
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.customer-field-row {
  gap: 24px;
}

.top16 {
  margin-top: 16px;
}

.top24 {
  margin-top: 24px;
}

.add-object-to-start {
  font-family: "Geomanist-Light", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;

  /* black */

  color: #000000;
}

.right-col {
  gap: 16px;
  max-width: calc(100% - 464px);
  background: #fff;
  border-radius: 8px;
  padding: 0;
  margin-bottom: 48px;
}

.right-col-header {
  display: flex;
  justify-content: space-between;
  padding: 32px 0px;
  align-items: center;
}

.objects-wrapper {
  display: flex;
  position: relative;
  min-width: 560px;
  //margin-top: 12px;
  padding-left: 32px;
  flex-direction: column;
  width: 100%;
  min-height: 502px;
  background-color: #f4f5f7;
}

.align-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.align-center {
  justify-content: center;
  align-items: center;
}

.generate-doc-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  //padding: 12px 12px;
  font-size: 14px;
  width: 200px;
  height: 40px;
  font-family: Inter;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
</style>
